import { __awaiter, __generator } from "tslib";
import { Resource } from '@ekuaibao/fetch';
import { showMessage } from '@ekuaibao/show-util';
import { Fetch } from '@ekuaibao/fetch';
import key from './key';
var dataLinkV2 = new Resource('/api/v2/datalink');
var remuneration2 = new Resource('/api/v2/remuneration/datalink');
var remunerationConfig = new Resource('/api/v2/remuneration/config');
var extensionCenterConfig = new Resource('/api/v1/flow/extensionCenterConfig');
var attachments = new Resource('/api/v1/attachment/attachments');
var voucherOcr = new Resource('/api/v2/remuneration/voucherOcr');
export function getResultList(flowId) {
    return {
        type: key.GETRESULTLIST,
        payload: voucherOcr.GET('/resultList/$flowId', flowId),
    };
}
export function exportRuleTemplate(uploadBatchId) {
    return {
        type: key.EXPORTRULETEMPLATE,
        payload: voucherOcr.GET('/export/$uploadBatchId', uploadBatchId),
    };
}
export function getMultipleItems(params) {
    return {
        type: key.GETMULTIPLEITEMS,
        payload: voucherOcr.POST('/multiple_items', params),
    };
}
export function saveOrUpdateRuleTemplate(params) {
    return {
        type: key.SAVEORUPDATERULETEMPLATE,
        payload: extensionCenterConfig.PUT('/save', params),
    };
}
export function searchRuleTemplate() {
    var params = {
        limit: {
            start: 0,
            count: 10,
        },
        select: 'id,name,desc,active,updateTime,configDetail',
        filterBy: 'type=="POWER_111100"',
    };
    return {
        type: key.SEARCHRULETEMPLATE,
        payload: extensionCenterConfig.POST('/search', params),
    };
}
export function getRuleTemplateDetails(id) {
    return {
        type: key.GET_REMUNERATION_TAB,
        payload: extensionCenterConfig.GET('/$id', id),
    };
}
export function downloadTemplate(id) {
    return {
        type: key.DOWNLOADTEMPLATE,
        payload: attachments.GET('/[flowId]', { flowId: id }),
    };
}
export function getExternalFields() {
    return __awaiter(this, void 0, void 0, function () {
        var result, e_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, remunerationConfig.GET('/getFields')];
                case 1:
                    result = _a.sent();
                    return [2 /*return*/, result.items];
                case 2:
                    e_1 = _a.sent();
                    showMessage.error(e_1.message || e_1.msg);
                    return [2 /*return*/, Promise.reject(e_1)];
                case 3: return [2 /*return*/];
            }
        });
    });
}
export function getRemunerationSetting() {
    return __awaiter(this, void 0, void 0, function () {
        var result, e_2;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, remunerationConfig.GET('/getSetting')];
                case 1:
                    result = _a.sent();
                    return [2 /*return*/, result.value];
                case 2:
                    e_2 = _a.sent();
                    return [2 /*return*/, Promise.reject(e_2)];
                case 3: return [2 /*return*/];
            }
        });
    });
}
export function saveRemunerationSetting(params) {
    return __awaiter(this, void 0, void 0, function () {
        var result, e_3;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, remunerationConfig.POST('/saveRemunerationSetting', params)];
                case 1:
                    result = _a.sent();
                    showMessage.success(i18n.get('保存成功'));
                    return [2 /*return*/, result];
                case 2:
                    e_3 = _a.sent();
                    showMessage.error(e_3.message || e_3.msg);
                    return [2 /*return*/, Promise.reject(e_3)];
                case 3: return [2 /*return*/];
            }
        });
    });
}
export function updateRemunerationSetting(params) {
    return __awaiter(this, void 0, void 0, function () {
        var paramsKeys, p_1, result, e_4;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    paramsKeys = [
                        'id',
                        'requestUrl',
                        'feeTypeId',
                        'fieldsMapping',
                        'specificationId',
                        'configureVoucher',
                    ];
                    p_1 = {};
                    paramsKeys.forEach(function (item) {
                        p_1[item] = params[item];
                    });
                    return [4 /*yield*/, remunerationConfig.PUT('/updateRemunerationSetting/$id', p_1)];
                case 1:
                    result = _a.sent();
                    showMessage.success(i18n.get('保存成功'));
                    return [2 /*return*/, result];
                case 2:
                    e_4 = _a.sent();
                    showMessage.error(e_4.message || e_4.msg);
                    return [2 /*return*/, Promise.reject(e_4)];
                case 3: return [2 /*return*/];
            }
        });
    });
}
export function geExternalTabPane(specificationId) {
    return {
        type: key.GET_REMUNERATION_TAB,
        payload: remunerationConfig.GET('/isShowTab/$specificationId', specificationId),
    };
}
export function getRemunerationBatchId() {
    return {
        type: key.GET_REMUNERATION_BATCH_ID,
        payload: dataLinkV2.GET('/getRemunerationBatchId'),
    };
}
export function searchRemunerationTable(params) {
    return {
        type: key.SEARCH_REMUNERATION_TABLE,
        payload: dataLinkV2.POST('/searchRemunerationDatalink/$dataLinkId/$onlyerror', params),
    };
}
export function addRemunerationDatalink(params) {
    return {
        type: key.ADD_REMUNERATION_DATALINK,
        payload: dataLinkV2.POST('/addDataEntityData', params),
    };
}
export function editRemunerationDatalink(params) {
    return {
        type: key.EDIT_REMUNERATION_DATALINK,
        payload: dataLinkV2.PUT('/updateDataEntityData', params),
    };
}
export function deleteRemuneration(params) {
    return {
        type: key.DELETE_REMUNERATION_DATALINK,
        payload: Fetch.DELETE('/api/v2/datalink/delDataEntityData', null, { body: params }),
    };
}
export function getLedgerSumMoney(params) {
    return {
        type: key.GET_LEDGER_SUM_MONEY,
        payload: remuneration2.GET('/getRemunerationLedgerProcess/$flowId/$batchId', params),
    };
}
export function getRemunerationConfigSetting() {
    return {
        type: key.GET_REMUNERATION_CONFIG_SETTING,
        payload: remunerationConfig.GET('/getSetting'),
    };
}
