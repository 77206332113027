import { __assign, __awaiter, __generator } from "tslib";
import React from 'react';
import { app as api } from '@ekuaibao/whispered';
import { cloneDeep, set, get } from 'lodash';
import loadable from '@loadable/component';
import { getRemunerationSetting } from '../remuneration.action';
export var layout = {
    labelCol: { span: 24, style: { fontSize: '14px' } },
    wrapperCol: { span: 24 }
};
export var actionMapTitle = {
    add: i18n.get('添加酬金明细'),
    edit: i18n.get('修改酬金明细'),
    look: i18n.get('查看酬金明细')
};
export function handleValues(fields, values, action, value) {
    var val = cloneDeep(values);
    var obj = fields.find(function (item) { return !item.formula && item.type === 'money'; });
    var _a = val[obj.name], _b = _a.standardScale, standardScale = _b === void 0 ? 2 : _b, _c = _a.standard, standard = _c === void 0 ? 0 : _c;
    val[obj.name] = Number(standard).toFixed(standardScale);
    if (action === 'edit') {
        var obj1 = fields.find(function (item) { return item.label === '错误原因'; }); // @i18n-ignore
        val[obj1.name] = '';
        Object.keys(value)
            .filter(function (item) {
            return item.endsWith('_code');
        })
            .map(function (item) {
            val[item] = value[item];
        });
    }
    return val;
}
export function setStyles(columns) {
    if (columns === void 0) { columns = []; }
    var arr = [];
    var obj = columns.find(function (item) { return item.label === '错误原因'; }); // @i18n-ignore
    var renderFn = setColunmStyle();
    obj && set(obj, 'render', renderFn.render);
    arr = columns.filter(function (item) { return !['错误原因'].includes(item.label); }); // @i18n-ignore
    arr.unshift(obj);
    return arr;
}
export function setVisibleColumns(columns, tableDataSource) {
    if (columns === void 0) { columns = []; }
    if (tableDataSource === void 0) { tableDataSource = []; }
    var filterArr = ['错误原因', '明细编码']; // @i18n-ignore
    var visibleColumns = columns
        .map(function (col) {
        if (!filterArr.includes(col.label)) {
            return col.dataIndex;
        }
    })
        .filter(function (item) { return item; });
    var arr1 = tableDataSource.filter(function (item) {
        var str = Object.keys(item.dataLink).find(function (item) { return item.endsWith('错误原因'); }); // @i18n-ignore
        return item.dataLink[str];
    });
    var obj = columns.find(function (item) { return item.label === '错误原因'; }); // @i18n-ignore
    if (arr1.length) {
        visibleColumns.unshift(obj.dataIndex);
    }
    return { visibleColumns: visibleColumns };
}
function setColunmStyle() {
    var EKBIcon = api.require('@elements/ekbIcon');
    return {
        render: function (text) {
            return (React.createElement("div", null, text ? (React.createElement(React.Fragment, null,
                React.createElement(EKBIcon, { name: "#EDico-warning-circle", style: { color: '#F4526B', width: 14, height: 14, marginRight: 4 } }),
                React.createElement("span", { style: { color: '#F4526B' } }, text))) : ('-')));
        }
    };
}
function setMoneytoZero(totalMoney) {
    if (totalMoney === void 0) { totalMoney = { standard: 0 }; }
    var obj = cloneDeep(totalMoney);
    obj.standard = 0;
    return obj;
}
function standardValueMoney(value, currency) {
    var standardCurrency = currency ? currency : api.getState()['@common'].standardCurrency;
    var strCode = standardCurrency.strCode, numCode = standardCurrency.numCode, symbol = standardCurrency.symbol, unit = standardCurrency.unit, scale = standardCurrency.scale;
    return {
        standard: value,
        standardStrCode: strCode,
        standardNumCode: numCode,
        standardSymbol: symbol,
        standardUnit: unit,
        standardScale: scale
    };
}
export function generateDetailToForm(params) {
    var totalMoney = params.totalMoney, feeTypeId = params.feeTypeId, specificationId = params.specificationId, isCalcEnd = params.isCalcEnd;
    var fee = {
        feeTypeId: __assign(__assign({}, feeTypeId), { feeType: feeTypeId }),
        feeTypeForm: {
            amount: !isCalcEnd ? setMoneytoZero(totalMoney) : totalMoney
        },
        specificationId: specificationId,
        isRemuneration: true,
        idx: 0
    };
    return [fee];
}
export function getRemunerationNum(params) {
    var dataLinkId = params.dataLinkId, onlyerror = params.onlyerror;
    return new Promise(function (resolve) {
        api
            .invokeService('@remuneration:search:remuneration:table', {
            dataLinkId: dataLinkId,
            onlyerror: onlyerror.toString(),
            limit: { start: 0, count: 10 }
        })
            .then(function (res) {
            var total = res.items.total;
            resolve({ total: total });
        });
    });
}
export function checkLedgerCalcEnd(params) {
    var formValue = params.formValue, props = params.props, isShowRemunerationTab = params.isShowRemunerationTab;
    return new Promise(function (resolve, reject) {
        if (!isShowRemunerationTab) {
            return resolve(formValue);
        }
        var dataSource = props.dataSource, remunerationBatchField = props.remunerationBatchField, bus = props.bus;
        var flowId = get(dataSource, 'id');
        var batchId = get(dataSource, "form." + remunerationBatchField + ".id");
        var str = i18n.get('酬金明细');
        if (!batchId) {
            return reject({
                remuneratin: {
                    errors: [{ message: i18n.get('请填写{__k0}', { __k0: str }) }]
                }
            });
        }
        getRemunerationNum({ dataLinkId: batchId, onlyerror: false }).then(function (res) {
            // @ts-ignore
            var total1 = res.total;
            if (total1 === 0) {
                return reject({
                    remuneratin: {
                        errors: [{ message: i18n.get('请填写{__k0}', { __k0: str }) }]
                    }
                });
            }
            getRemunerationNum({ dataLinkId: batchId, onlyerror: true }).then(function (res) {
                // @ts-ignore
                var total2 = res.total;
                if (total2 > 0) {
                    return reject({
                        remuneratin: {
                            errors: [{ message: i18n.get('该单据中存在尚未修改的酬金明细') }]
                        }
                    });
                }
                return api.invokeService('@remuneration:get:ledger:sum:money', { flowId: flowId, batchId: batchId }).then(function (res) {
                    var _a = res.value, end = _a.end, ledgerAmount = _a.ledgerAmount;
                    if (end) {
                        set(formValue, "details[0].feeTypeForm.amount", ledgerAmount);
                        var details = get(formValue, 'details');
                        bus.setFieldsValue({ details: details });
                        resolve(formValue);
                    }
                    else {
                        reject({ remuneratin: { errors: [{ message: i18n.get('酬金明细台账金额未计算完毕') }] } });
                    }
                });
            });
        });
    });
}
export function creatTabs(params) {
    var bus = params.bus, dataSource = params.dataSource, remunerationBatchField = params.remunerationBatchField, isReadonly = params.isReadonly;
    var str1 = get(dataSource, 'form.specificationId.id');
    var str2 = get(dataSource, 'currentSpecification.id');
    var str3 = get(dataSource, 'requisitionInfo.defaultSpecification.id');
    var specificationId = str1 || str2 || str3;
    var dataLinkId = get(dataSource, "form." + remunerationBatchField + ".id");
    return new Promise(function (resolve) {
        if (!specificationId) {
            return resolve({ value: null });
        }
        api.invokeService('@remuneration:get:remuneration:tab', { specificationId: specificationId }).then(function (res) {
            var isShowTab = res.value;
            if (isShowTab) {
                if (!dataLinkId) {
                    return resolve({ value: generateTabs({ bus: bus, dataSource: dataSource, iconType: '', isReadonly: isReadonly }) });
                }
                return getRemunerationNum({ dataLinkId: dataLinkId, onlyerror: true }).then(function (res) {
                    // @ts-ignore
                    var total = res.total;
                    var iconType = total > 0 ? 'error' : '';
                    return resolve({ value: generateTabs({ bus: bus, dataSource: dataSource, iconType: iconType, isReadonly: isReadonly }) });
                });
            }
            return resolve({ value: null });
        });
    });
}
export function initDetails(params) {
    var bus = params.bus, dataSource = params.dataSource, remunerationBatchField = params.remunerationBatchField, isReadonly = params.isReadonly;
    var str1 = get(dataSource, 'form.specificationId.id');
    var str2 = get(dataSource, 'currentSpecification.id');
    var str3 = get(dataSource, 'requisitionInfo.defaultSpecification.id');
    var specificationId = str1 || str2 || str3;
    //const dataLinkId = get(dataSource, `form.${remunerationBatchField}.id`)
    return new Promise(function (resolve) {
        if (!specificationId) {
            return resolve({ value: null });
        }
        api.invokeService('@remuneration:get:remuneration:tab', { specificationId: specificationId }).then(function (res) {
            var isShowTab = res.value;
            if (isShowTab) {
                !isReadonly && addRemunerationDetail(params);
            }
            return resolve({ value: null });
        });
    });
}
export function generateTabs(params) {
    var RemunerationDetail = loadable(function () { return import('../using/RemunerationDetail'); });
    var bus = params.bus, dataSource = params.dataSource, iconType = params.iconType, isReadonly = params.isReadonly;
    return {
        tab: i18n.get('酬金明细'),
        iconType: iconType,
        key: 'remuneration',
        children: React.createElement(RemunerationDetail, { bus: bus, dataSource: dataSource, isReadonly: isReadonly })
    };
}
export function addRemunerationDetail(params) {
    return __awaiter(this, void 0, void 0, function () {
        var bus, dataSource, remunerationBatchField, _a, feeTypeId, formProperSetName, value, expenseSpecificationId, action, specificationId, entityInfo, batchId, flowId, details;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    bus = params.bus, dataSource = params.dataSource, remunerationBatchField = params.remunerationBatchField;
                    return [4 /*yield*/, getRemunerationSetting()];
                case 1:
                    _a = _b.sent(), feeTypeId = _a.feeTypeId, formProperSetName = _a.formProperSetName;
                    return [4 /*yield*/, api.invokeService('@custom-feetype:getFeeTypeById', { id: feeTypeId })];
                case 2:
                    value = (_b.sent()).value;
                    expenseSpecificationId = value.expenseSpecificationId;
                    return [4 /*yield*/, api.invokeService('@bills:import:getFeeTypeTemplateById', expenseSpecificationId)];
                case 3:
                    action = _b.sent();
                    specificationId = action.value;
                    entityInfo = get(dataSource, "form." + (remunerationBatchField ? remunerationBatchField : formProperSetName));
                    batchId = get(entityInfo, 'id', entityInfo);
                    flowId = get(dataSource, 'id');
                    if (flowId && entityInfo) {
                        api.invokeService('@remuneration:get:ledger:sum:money', { flowId: flowId, batchId: batchId }).then(function (res) {
                            var _a = res.value, end = _a.end, ledgerAmount = _a.ledgerAmount;
                            var details = generateDetailToForm({ totalMoney: ledgerAmount, feeTypeId: value, specificationId: specificationId, isCalcEnd: end });
                            bus.emit('dynamic:detail:change', details);
                        });
                    }
                    else {
                        details = generateDetailToForm({ totalMoney: standardValueMoney('0.00'), feeTypeId: value, specificationId: specificationId, isCalcEnd: true });
                        bus.emit('dynamic:detail:change', details);
                    }
                    return [2 /*return*/];
            }
        });
    });
}
export function firstSaveBill(params) {
    var bus = params.bus;
    bus.emit('footer:action:save', { autoOpenDetail: true });
}
export function setBatchId(params) {
    var remunerationBatchField = params.remunerationBatchField, dataSource = params.dataSource, isShowRemunerationTab = params.isShowRemunerationTab, formValue = params.formValue;
    return new Promise(function (resolve) {
        var id = dataSource.id, isCopy = dataSource.isCopy;
        var arr1 = get(dataSource, 'form.specificationId.components', []);
        var arr2 = get(dataSource, 'currentSpecification.components', []);
        var arr3 = get(dataSource, 'requisitionInfo.defaultSpecification.components', []);
        var billCom = id
            ? arr1
            : isCopy
                ? arr1
                : arr2;
        billCom = billCom.length === 0 ? arr3 : billCom;
        var obj = billCom.find(function (item) { return item.field === remunerationBatchField; });
        var str = get(dataSource, "form." + remunerationBatchField + ".id");
        if (!obj) {
            formValue[remunerationBatchField] ? delete formValue[remunerationBatchField] : null;
            return resolve(formValue);
        }
        if (!str && isShowRemunerationTab) {
            api.invokeService('@remuneration:get:remuneration:batchId').then(function (res) {
                var id = res.id;
                formValue[remunerationBatchField] = id;
                resolve(formValue);
            });
        }
        else {
            resolve(formValue);
        }
    });
}
