var _a;
import key from './key';
import { app as api } from '@ekuaibao/whispered';
import { getRemunerationConfigSetting, getRemunerationBatchId, geExternalTabPane, getLedgerSumMoney, searchRemunerationTable, addRemunerationDatalink, deleteRemuneration, editRemunerationDatalink, saveOrUpdateRuleTemplate, getRuleTemplateDetails, downloadTemplate, searchRuleTemplate, getMultipleItems, exportRuleTemplate, getResultList, } from './remuneration.action';
import { checkLedgerCalcEnd, getRemunerationNum, creatTabs, initDetails, firstSaveBill, setBatchId, generateTabs, } from './utils/utilsFns';
import loadable from '@loadable/component';
export default [
    {
        id: key.ID,
        reducer: function () { return import('./remuneration.reducer'); },
        path: '/remuneration',
        ref: '/',
        'set:batchId': function (params) { return setBatchId(params); },
        'generate:tabs': function (params) { return generateTabs(params); },
        'init:details': function (params) { return initDetails(params); },
        'first:save:bill': function (params) { return firstSaveBill(params); },
        'get:details:num': function (params) { return getRemunerationNum(params); },
        'creat:tabs': function (params) { return creatTabs(params); },
        'check:ledger:calcEnd': function (params) { return checkLedgerCalcEnd(params); },
        'get:remuneration:config:setting': function () { return api.dispatch(getRemunerationConfigSetting()); },
        'get:remuneration:tab': function (params) { return api.dispatch(geExternalTabPane(params)); },
        'get:ledger:sum:money': function (params) { return api.dispatch(getLedgerSumMoney(params)); },
        'search:remuneration:table': function (params) { return api.dispatch(searchRemunerationTable(params)); },
        'add:remuneration:dataLink': function (params) { return api.dispatch(addRemunerationDatalink(params)); },
        'edit:remuneration:dataLink': function (params) { return api.dispatch(editRemunerationDatalink(params)); },
        'delete:remuneration:dataLink': function (params) { return api.dispatch(deleteRemuneration(params)); },
        'get:remuneration:batchId': function () { return api.dispatch(getRemunerationBatchId()); },
        // 添加/更新模板配置
        'handle:audit:rule:template': function (params) { return api.dispatch(saveOrUpdateRuleTemplate(params)); },
        // 获取单个模板详情
        'get:single:rule:template:details': function (id) { return api.dispatch(getRuleTemplateDetails(id)); },
        // 下载模板文件
        'download:template': function (id) { return api.dispatch(downloadTemplate(id)); },
        // 查询模板列表
        'search:rule:template': function () { return api.dispatch(searchRuleTemplate()); },
        // 凭证稽核OCR
        'get:multiple:items': function (params) { return api.dispatch(getMultipleItems(params)); },
        // 导出对比结果excel文件
        'export:rule:template': function (uploadBatchId) {
            return api.dispatch(exportRuleTemplate(uploadBatchId));
        },
        // 3. 对比结果列表接口
        'get:result:list': function (flowId) { return api.dispatch(getResultList(flowId)); },
    },
    {
        point: '@@components',
        namespace: key.ID,
        onload: function () { return [
            { key: 'remunerationConfig', component: function () { return import('./config/RemunerationView'); } },
        ]; },
    },
    {
        point: '@@layers',
        prefix: key.ID,
        onload: function () { return import('./layers'); },
    },
    {
        resource: '@remuneration',
        value: (_a = {},
            _a['using/RemunerationDetail'] = loadable(function () { return import('./using/RemunerationDetail'); }),
            _a['using/RemunerationTabs'] = loadable(function () { return import('./using/RemunerationTabs'); }),
            _a),
    },
];
