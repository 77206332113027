export var ID = '@remuneration';
export default {
    ID: ID,
    GET_REMUNERATION_CONFIG_SETTING: ID + "/GET_REMUNERATION_CONFIG_SETTING",
    SEARCH_REMUNERATION_TABLE: ID + "/SEARCH_REMUNERATION_TABLE",
    GET_REMUNERATION_BATCH_ID: ID + "/GET_REMUNERATION_BATCH_ID",
    ADD_REMUNERATION_DATALINK: ID + "/ADD_REMUNERATION_DATALINK",
    EDIT_REMUNERATION_DATALINK: ID + "/EDIT_REMUNERATION_DATALINK",
    DELETE_REMUNERATION_DATALINK: ID + "/DELETE_REMUNERATION_DATALINK",
    GET_LEDGER_SUM_MONEY: ID + "/GET_LEDGER_SUM_MONEY",
    GET_REMUNERATION_TAB: ID + "/GET_REMUNERATION_TAB",
    GETRULETEMPLATEDETAILS: ID + "/GETRULETEMPLATEDETAILS",
    DOWNLOADTEMPLATE: ID + "/DOWNLOADTEMPLATE",
    SEARCHRULETEMPLATE: ID + "/SEARCHRULETEMPLATE",
    SAVEORUPDATERULETEMPLATE: ID + "/SAVEORUPDATERULETEMPLATE",
    GETMULTIPLEITEMS: ID + "/GETMULTIPLEITEMS",
    EXPORTRULETEMPLATE: ID + "/EXPORTRULETEMPLATE",
    GETRESULTLIST: ID + "/GETRESULTLIST",
};
